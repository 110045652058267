import React, { useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../../_metronic";
import SelectDeviceDialog from "../../../components/SelectDeviceDialog";
import {
    Paper,
    Button,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Typography,
    FormControl,
    InputLabel,
    Select
} from "@material-ui/core";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeDownIcon from "@material-ui/icons/VolumeDown";
import { Portlet, PortletBody } from "../../../partials/content/Portlet";
import Notify from "../../../components/Notify";
import Notice from "../../../partials/content/Notice";
import ConferenceVideo from "./ConferenceVideo";
import { meeting as useStyles } from "./CommonStyles";

import * as eventStore from "../../../store/ducks/event.duck";
import { ORIGINAL_ROOMNAME } from "../../../components/JitsiMeeting";
const JitsiMeetJS = window.JitsiMeetJS;

function Conference(props) {
    const {
        isEvent,
        event,
        isStartMeeting,
        startMeeting,
        endMeeting,
        screenMode,
        openAudioOutputSettingDlg,
        setOpenAudioOutputSettingDlg,
        intl
    } = props;
    const classes = useStyles(props);
    const [lang, setLang] = React.useState(ORIGINAL_ROOMNAME);
    const [volume, setVolume] = React.useState(0.5);
    const [isOriginal, setIsOriginal] = React.useState(false);
    const [roomsList, setRoomsList] = React.useState([]);
    const [audioOutputSetting, setAudioOutputSetting] = React.useState("");
    const [audioOutputDevices, setAudioOutputDevices] = React.useState([]);

    const restart = localStorage.getItem("restart");

    useEffect(() => {
        let _audioOutputDevices = [];
        if (JitsiMeetJS && JitsiMeetJS.mediaDevices.isDeviceChangeAvailable("output")) {
            JitsiMeetJS.mediaDevices.enumerateDevices(devices => {
                devices.forEach((device, i) => {
                    switch (device.kind) {
                        case "audiooutput":
                            _audioOutputDevices.push(device);
                            break;
                        default:
                            break;
                    }
                });

                // TODO: Check if states are updated above
                if (_audioOutputDevices.length > 0) {
                    setAudioOutputDevices(_audioOutputDevices);
                    setAudioOutputSetting(_audioOutputDevices[0].deviceId);
                }
            });
        }
    }, [JitsiMeetJS]);

    useEffect(() => {
        if (isEvent && event) {
            const event_rooms = event.event_rooms;
            var resArr = [];
            event_rooms.filter(function(item) {
                var i = resArr.findIndex(x => x.room.room_name === item.room.room_name);
                if (i <= -1) {
                    resArr.push(item);
                }
                return null;
            });
            setRoomsList(resArr);

            if (restart === "1") {
                localStorage.removeItem("restart");
                handleStartMeeting();
            }
        }
    }, [isEvent, event]);

    function handleStartMeeting() {
        if (lang) {
            startMeeting();
        }
    }

    function handleEndMeeting() {
        endMeeting();
    }

    function handleReset() {
        localStorage.setItem("restart", 1);
        window.location.reload();
    }

    function handleLangChange(value) {
        setLang(value);
    }

    function handleDown(event) {
        setVolume(prevState => {
            if (prevState >= 0.1) {
                return prevState - 0.1;
            } else {
                return 0.0;
            }
        });
    }

    function handleUp(event) {
        setVolume(prevState => {
            if (prevState <= 0.9) {
                return prevState + 0.1;
            } else {
                return 1.0;
            }
        });
    }

    function handleOriginal() {
        setIsOriginal(prevState => !prevState);
    }

    /**
     * Handle AudioOutputSetting Change
     * @param {String} value
     */
    function handleAudioOutputSettingChange(value) {
        setAudioOutputSetting(value);
        JitsiMeetJS.mediaDevices.setAudioOutputDevice(value);
    }

    return (
        <div id="eventContainer">
            <Notice
                className={`notice__paragraph ${classes.headerContent} ${isStartMeeting &&
                    classes.headerShadowNone}`}
                id="content_header"
            >
                <div>
                    <img
                        className={classes.avatar}
                        src={
                            event.pic
                                ? process.env.REACT_APP_FILE_URL + event.pic
                                : toAbsoluteUrl("/media/logos/logo-sm.png")
                            // toAbsoluteUrl("/media/products/product1.jpg")
                        }
                        alt="avatar"
                    />
                </div>
                <Typography variant="h6" className={classes.title}>
                    {event.title}
                </Typography>
                <Typography className={classes.description} variant="subtitle1">
                    {event.detail}
                </Typography>
            </Notice>
            <div className={`container row ${classes.eventPlace}`} id="eventPlace">
                <div
                    className={`col-md-6 ${classes.control} ${screenMode < 0 &&
                        classes.fullWidthControl}`}
                >
                    <div className="row">
                        <div className="col-md-12">
                            <Portlet>
                                <PortletBody>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container justifyContent="center" spacing={2}>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    style={{
                                                        textAlign: "end"
                                                    }}
                                                >
                                                    {isStartMeeting ? (
                                                        <Button
                                                            variant="contained"
                                                            size="medium"
                                                            color="primary"
                                                            className={classes.buttonRed}
                                                            disabled={!isStartMeeting}
                                                            onClick={handleEndMeeting}
                                                        >
                                                            {intl.formatMessage({
                                                                id: "EVENT.ACTION.END_MEETING"
                                                            })}
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant="contained"
                                                            size="medium"
                                                            color="primary"
                                                            className={classes.buttonRed}
                                                            disabled={isStartMeeting}
                                                            onClick={handleStartMeeting}
                                                        >
                                                            {intl.formatMessage({
                                                                id: "EVENT.ACTION.START_MEETING"
                                                            })}
                                                        </Button>
                                                    )}
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    style={{
                                                        textAlign: "start"
                                                    }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        size="medium"
                                                        color="primary"
                                                        className={classes.buttonRed}
                                                        disabled={!isStartMeeting}
                                                        onClick={handleReset}
                                                    >
                                                        {intl.formatMessage({
                                                            id: "EVENT.ACTION.RESET"
                                                        })}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </PortletBody>
                            </Portlet>
                        </div>
                        <div className="col-md-12">
                            <Paper className={classes.volumeControl}>
                                <Grid item xs={12}>
                                    <FormControl
                                        variant="outlined"
                                        disabled={false}
                                        className={classes.formControl}
                                    >
                                        <InputLabel htmlFor="side_audioOutputSetting">
                                            {intl.formatMessage({
                                                id: "VIDEO.SETTING.AUDIO_OUPUTS.TITLE"
                                            })}
                                        </InputLabel>
                                        <Select
                                            native
                                            onChange={e =>
                                                handleAudioOutputSettingChange(e.target.value)
                                            }
                                            displayEmpty
                                            inputProps={{
                                                name: "side_audioOutputSetting",
                                                id: "side_audioOutputSetting"
                                            }}
                                            value={
                                                audioOutputSetting === "" &&
                                                audioOutputDevices &&
                                                audioOutputDevices.length > 0
                                                    ? audioOutputDevices[0].deviceId
                                                    : audioOutputSetting
                                            }
                                        >
                                            {audioOutputDevices.map(device => {
                                                return (
                                                    <option
                                                        key={device.deviceId}
                                                        value={device.deviceId}
                                                    >
                                                        {device.label}
                                                    </option>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Paper>
                        </div>
                        <div className="col-md-12">
                            <Paper className={classes.volumeControl}>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    className={classes.volume}
                                    onClick={handleDown}
                                >
                                    <VolumeDownIcon />
                                </Button>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    className={classes.volume}
                                    onClick={handleUp}
                                >
                                    <VolumeUpIcon />
                                </Button>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color={`${isOriginal ? "primary" : "default"}`}
                                    className={classes.volume}
                                    onClick={handleOriginal}
                                >
                                    + Ori
                                </Button>
                            </Paper>
                        </div>
                    </div>
                </div>
                <div className={`col-md-6 ${classes.videoPlace}`} id="videoPlace">
                    <Notify event={event} />
                    {event && isStartMeeting ? (
                        <ConferenceVideo
                            event={event}
                            roomname={lang}
                            volume={volume}
                            size="sm"
                            isOriginal={isOriginal}
                            setAudioOutputDevices={setAudioOutputDevices}
                            setAudioOutputSetting={setAudioOutputSetting}
                        />
                    ) : (
                        ""
                    )}

                    <Paper className={classes.langControl}>
                        <List>
                            <ListItem
                                button
                                key={ORIGINAL_ROOMNAME}
                                selected={ORIGINAL_ROOMNAME === lang}
                                onClick={() => handleLangChange(ORIGINAL_ROOMNAME)}
                            >
                                <ListItemText
                                    primary={intl.formatMessage({
                                        id: "EVENT.LANG.ORIGINAL"
                                    })}
                                />
                            </ListItem>
                            {event &&
                                roomsList.length > 0 &&
                                roomsList
                                    .filter(row => row.room.output_lang)
                                    .map((row, index) => {
                                        return (
                                            <ListItem
                                                button
                                                key={index}
                                                selected={row.room.room_name === lang}
                                                onClick={() => handleLangChange(row.room.room_name)}
                                            >
                                                <ListItemAvatar>
                                                    {/* <Avatar> */}
                                                    <img
                                                        src={toAbsoluteUrl(
                                                            `/media/flags-svg/${row.room.output_lang.lang_flag}`
                                                        )}
                                                        alt={row.room.output_lang.lang_name}
                                                        className={classes.flag}
                                                    />
                                                    {/* </Avatar> */}
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={row.room.output_lang.lang_label}
                                                />
                                            </ListItem>
                                        );
                                    })}
                        </List>
                    </Paper>
                </div>

                <SelectDeviceDialog
                    id="audioOutputSetting"
                    title={intl.formatMessage({
                        id: "VIDEO.SETTING.AUDIO_OUPUTS.TITLE"
                    })}
                    label={intl.formatMessage({
                        id: "VIDEO.SETTING.AUDIO_OUPUTS"
                    })}
                    isOpen={openAudioOutputSettingDlg}
                    devices={audioOutputDevices}
                    currentValue={audioOutputSetting}
                    onChange={handleAudioOutputSettingChange}
                />
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        event: state.customerReducer.event,
        isEvent: state.customerReducer.isEvent,
        isStartMeeting: state.event.isStartMeeting,
        screenMode: state.event.screenMode,
        openAudioOutputSettingDlg: state.event.openAudioOutputSettingDlg
    };
};

const mapDispatchToProps = dispatch => ({
    startMeeting: () => dispatch(eventStore.actions.startMeeting()),
    endMeeting: () => dispatch(eventStore.actions.endMeeting()),
    setOpenAudioOutputSettingDlg: isOpen =>
        dispatch(eventStore.actions.setOpenAudioOutputSettingDlg(isOpen))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Conference));
