import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { makeStyles, colors, IconButton, Fab, Badge } from "@material-ui/core";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import PanToolIcon from "@material-ui/icons/PanTool";
import CallEndIcon from "@material-ui/icons/CallEnd";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import RemoveFromQueueIcon from "@material-ui/icons/RemoveFromQueue";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import SettingsVoiceIcon from "@material-ui/icons/SettingsVoice";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import SpeakerIcon from "@material-ui/icons/Speaker";
import MessageIcon from "@material-ui/icons/Message";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import SubtitlesIcon from "@material-ui/icons/Subtitles";
import GroupIcon from "@material-ui/icons/Group";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import StopIcon from "@material-ui/icons/Stop";
import { Dropdown } from "react-bootstrap";
import CustomMenu from "./CustomMenu";
import CustomToggle from "./CustomToggle";
import OscilloMeter from "./OscilloMeter";
import * as eventStore from "../store/ducks/event.duck";
import * as eventsStore from "../store/ducks/admins/index.duck";
import { UserRole } from "./utils/UserRole";
import { jitsiMeeting } from "./JitsiMeeting";
import { EventType } from "./utils/EventType";
import { validMembers } from "./utils/RoomUtils";

const useStyles = makeStyles(theme => ({
    toolbox: {
        position: "relative",
        bottom: props => (props.isToolbarShow ? 0 : -56),
        left: "auto",
        right: "auto",
        zIndex: "500",
        width: "100%",
        // backgroundImage: "linear-gradient(to top,rgba(0,0,0,.6),rgba(0,0,0,0))",
        backgroundColor: "#525050",
        display: "flex",
        justifyContent: "center",
        transition: "bottom .3s ease-in"
    },
    toolboxLeft: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        alignSelf: "center",
        width: "40%"
    },
    toolboxCenter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        width: "20%"
    },
    toolboxRight: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        alignSelf: "center",
        width: "40%"
    },
    activeBtn: {
        margin: theme.spacing(1),
        color: colors.common.white,
        minWidth: "40px !important",
        width: "40px !important",
        height: "40px !important",
        [theme.breakpoints.down("xs")]: {
            height: "30px !important",
            width: "30px !important",
            minWidth: "30px !important",
            minHeight: "30px !important",
            margin: 2
        }
    },
    inactiveBtn: {
        margin: theme.spacing(1),
        backgroundColor: colors.red[800],
        color: colors.common.white,
        minWidth: "40px !important",
        width: "40px !important",
        height: "40px !important",
        [theme.breakpoints.down("xs")]: {
            height: "30px !important",
            width: "30px !important",
            minWidth: "30px !important",
            minHeight: "30px !important",
            margin: 2
        },
        "&:hover": {
            color: colors.common.white,
            backgroundColor: colors.red[300]
        }
    },
    "@keyframes blinker": {
        from: { backgroundColor: colors.yellow["A400"] },
        to: { backgroundColor: "#0abb87" }
    },
    chat: {
        animationName: "$blinker",
        animationDuration: "1s",
        animationTimingFunction: "linear",
        animationIterationCount: "infinite",
        "animation-fill-mode": "initial",
        "&:hover": {
            backgroundColor: colors.yellow[800]
        }
    },
    videoMute: {
        height: "40px !important",
        minWidth: "40px !important",
        borderRadius: "50% !important",
        [theme.breakpoints.down("xs")]: {
            height: "30px !important",
            width: "30px !important",
            minWidth: "30px !important",
            minHeight: "30px !important",
            margin: 2
        }
    },
    oscilloMeter: {
        position: "relative"
    },
    toolbarItem: {
        [theme.breakpoints.down("xs")]: {
            display: "none"
        }
    },
    settingItem: {
        [theme.breakpoints.up("sm")]: {
            display: "none"
        }
    }
}));

function Toolbar(props) {
    const {
        event,
        isMicOn,
        isMuted,
        isVideoOn,
        isChatBoxOpen,
        isNewMsg,
        isCamera,
        isRaise,
        isShowRecordDlg,
        isMosaic,
        cameraDevices,
        isShareOtherCamera,
        isMuteAll,
        disableVideo,
        isOscilloMeter,
        isSubTitle,
        showInfoPanel,
        lobbyMembers,
        intl
    } = props;
    const classes = useStyles(props);

    function handleMute(value) {
        console.log(isMicOn);
        console.log(value);
        if (isMicOn) jitsiMeeting.changeMute(value);
    }

    function handleVideoOn() {
        if (!disableVideo) jitsiMeeting.changeVideoOn(!isVideoOn);
    }

    function handleChat(value) {
        props.openChatBox(value);
    }

    function handleLeave() {
        props.endMeeting();
    }

    function shareOtherCamera(value) {
        jitsiMeeting.changeShareOtherCamera(value);
    }

    function switchVideo(value) {
        jitsiMeeting.setIsCamera(value);
    }

    function handleMosaicView() {
        props.updateIsMosaic(!isMosaic);
    }

    function handleSetSize(mode) {
        props.updateScreenMode(mode);
    }

    function handleVideoSetting() {
        props.setOpenVideoSettingDlg(true);
    }

    function handleAudioOutputSetting() {
        props.setOpenAudioOutputSettingDlg(true);
    }

    function handleAudioInputSetting(params) {
        props.setOpenAudioInputSettingDlg(true);
    }

    function handleRaiseHand(value) {
        props.setIsRaise(value);
    }

    function handleMuteOthers(value) {
        if (
            [UserRole.ADMIN, UserRole.SUPERADMIN, UserRole.EVENT_MANAGER].includes(props.user.role)
        ) {
            jitsiMeeting.muteParticipants(value);
        }
    }

    function handleOscilloMeter() {
        props.setIsOscilloMeter(!isOscilloMeter);
    }

    function handleSubtitleOn(value) {
        jitsiMeeting.setIsSubTitle(value);
    }

    function handleLobby(value) {
        props.updateShowInfoPanel(value);
    }

    function handleRecordDig(value) {
        props.showRecordDlg(value);
    }

    return (
        <div id="toolbox" className={classes.toolbox}>
            <div id="toolboxLeft" className={classes.toolboxLeft}>
                {!props.disableAudioInput &&
                    (!isMuted ? (
                        <Fab
                            color="secondary"
                            size="small"
                            aria-label="MicOn"
                            className={classes.activeBtn}
                            onClick={() => handleMute(true)}
                            disabled={!isMicOn}
                        >
                            <MicIcon />
                        </Fab>
                    ) : (
                        <Fab
                            color="inherit"
                            size="small"
                            aria-label="MicOff"
                            className={classes.inactiveBtn}
                            onClick={() => handleMute(false)}
                            disabled={!isMicOn}
                        >
                            <MicOffIcon />
                        </Fab>
                    ))}
                {!props.disableVideoInput &&
                    (isVideoOn ? (
                        <Fab
                            color="secondary"
                            size="small"
                            aria-label="Video On"
                            onClick={handleVideoOn}
                            className={`${classes.videoMute} ${classes.activeBtn}`}
                            disabled={disableVideo}
                        >
                            <VideocamIcon />
                        </Fab>
                    ) : (
                        <Fab
                            color="inherit"
                            size="small"
                            aria-label="Video Off"
                            onClick={handleVideoOn}
                            className={`${classes.inactiveBtn} ${classes.videoMute} ${classes.activeBtn}`}
                            disabled={disableVideo}
                        >
                            <VideocamOffIcon />
                        </Fab>
                    ))}
                {!props.disableRaiseHand && (
                    <IconButton
                        color="primary"
                        aria-label="Screen Size"
                        className={`${classes.activeBtn} ${classes.toolbarItem}`}
                        onClick={() => handleRaiseHand(!isRaise)}
                    >
                        <PanToolIcon />
                    </IconButton>
                )}
                <Fab
                    color="secondary"
                    size="small"
                    aria-label="Subtitle On/off"
                    onClick={() => handleSubtitleOn(!isSubTitle)}
                    className={`${!isSubTitle ? classes.inactiveBtn : ""} ${classes.videoMute} ${
                        classes.activeBtn
                    }`}
                    disabled={disableVideo}
                >
                    <SubtitlesIcon />
                </Fab>
            </div>
            <div id="toolboxCenter" className={classes.toolboxCenter}>
                {!props.disableChat && (
                    <Fab
                        color="secondary"
                        size="small"
                        aria-label="Chat"
                        className={`${classes.activeBtn} ${isNewMsg ? classes.chat : ""}`}
                        onClick={() => handleChat(!isChatBoxOpen)}
                    >
                        <MessageIcon />
                    </Fab>
                )}
                <Fab
                    color="inherit"
                    size="small"
                    aria-label="CallEnd"
                    className={classes.inactiveBtn}
                    onClick={handleLeave}
                >
                    <CallEndIcon />
                </Fab>
                {!props.disableShare && (
                    <Dropdown>
                        <Dropdown.Toggle
                            as={CustomToggle}
                            id="dropdown-basic"
                            drop={"up"}
                            navbar={false}
                        >
                            <Fab
                                color="secondary"
                                size="small"
                                aria-label="ShareScreen"
                                className={classes.activeBtn}
                            >
                                <ScreenShareIcon />
                            </Fab>
                        </Dropdown.Toggle>
                        <Dropdown.Menu as={CustomMenu}>
                            {cameraDevices && cameraDevices.length > 1 ? (
                                <Dropdown.Item
                                    eventKey="1"
                                    onClick={() => shareOtherCamera(!isShareOtherCamera)}
                                    active={isShareOtherCamera}
                                >
                                    <VideocamIcon />{" "}
                                    {intl.formatMessage({
                                        id: "VIDEO.SHARE.CAMERA"
                                    })}
                                </Dropdown.Item>
                            ) : (
                                ""
                            )}

                            <Dropdown.Item
                                eventKey="2"
                                onClick={() => switchVideo(!isCamera)}
                                active={!isCamera}
                            >
                                <DesktopWindowsIcon />{" "}
                                {intl.formatMessage({
                                    id: "VIDEO.SHARE.DESKTOP"
                                })}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )}
            </div>
            <div id="toolbox-right" className={classes.toolboxRight}>
                {props.isAdmin && (
                    <div className={classes.oscilloMeter}>
                        <IconButton
                            color="primary"
                            aria-label="Channel Audios"
                            className={classes.activeBtn}
                            onClick={handleOscilloMeter}
                        >
                            <EqualizerIcon />
                        </IconButton>
                        <OscilloMeter event={event} />
                    </div>
                )}
                {(event.event_type == EventType.MEETING ||
                    event.event_type == EventType.WEBINAR) && (
                    <IconButton
                        color="primary"
                        aria-label="Layout"
                        className={`${classes.activeBtn}`}
                        onClick={() => handleLobby(!showInfoPanel)}
                    >
                        <Badge
                            badgeContent={validMembers(lobbyMembers).length}
                            color="primary"
                            overlap="rectangular"
                        >
                            <GroupIcon />
                        </Badge>
                    </IconButton>
                )}

                <IconButton
                    color="primary"
                    aria-label="Layout"
                    className={`${classes.activeBtn} ${classes.toolbarItem}`}
                    onClick={handleMosaicView}
                >
                    <ViewModuleIcon />
                </IconButton>
                <Dropdown className={classes.toolbarItem}>
                    <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-basic"
                        drop={"up"}
                        navbar={false}
                    >
                        <IconButton
                            color="primary"
                            aria-label="Screen Size"
                            className={classes.activeBtn}
                        >
                            <AspectRatioIcon />
                        </IconButton>
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu}>
                        <Dropdown.Item eventKey="1" onClick={() => handleSetSize(0)}>
                            <RemoveFromQueueIcon />{" "}
                            {intl.formatMessage({ id: "VIDEO.SCREEN.NORMAL" })}
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="2" onClick={() => handleSetSize(1)}>
                            <FullscreenExitIcon />{" "}
                            {intl.formatMessage({ id: "VIDEO.SCREEN.LARGE" })}
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="3" onClick={() => handleSetSize(2)}>
                            <FullscreenIcon /> {intl.formatMessage({ id: "VIDEO.SCREEN.FULL" })}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                    <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-basic"
                        drop={"up"}
                        navbar={false}
                    >
                        <IconButton color="primary" aria-label="More" className={classes.activeBtn}>
                            <MoreVertIcon />
                        </IconButton>
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu}>
                        {!props.disableVideoInput && (
                            <Dropdown.Item eventKey="1" onClick={handleVideoSetting}>
                                <VideocamIcon />{" "}
                                {intl.formatMessage({
                                    id: "VIDEO.VIDEO_INPUT"
                                })}
                            </Dropdown.Item>
                        )}
                        {!props.disableAudioInput && (
                            <Dropdown.Item eventKey="3" onClick={handleAudioInputSetting}>
                                <SettingsVoiceIcon />{" "}
                                {intl.formatMessage({
                                    id: "VIDEO.AUDIO_INPUT"
                                })}
                            </Dropdown.Item>
                        )}
                        {!props.disableAudioOutput && (
                            <Dropdown.Item eventKey="2" onClick={handleAudioOutputSetting}>
                                <SpeakerIcon />{" "}
                                {intl.formatMessage({
                                    id: "VIDEO.AUDIO_OUTPUT"
                                })}
                            </Dropdown.Item>
                        )}
                        {props.isAdmin && (
                            <Dropdown.Item
                                eventKey="4"
                                onClick={() => handleMuteOthers(!isMuteAll)}
                                active={isMuteAll}
                            >
                                <MicOffIcon />{" "}
                                {intl.formatMessage({
                                    id: "TOOLBOX.SETTING.MUTE_EVERYONE"
                                })}
                            </Dropdown.Item>
                        )}
                        <Dropdown.Item
                            eventKey="5"
                            onClick={handleMosaicView}
                            className={classes.settingItem}
                        >
                            <ViewModuleIcon />{" "}
                            {intl.formatMessage({
                                id: "TOOLBOX.SETTING.LAYOUT"
                            })}
                        </Dropdown.Item>
                        {!props.disableRaiseHand && (
                            <Dropdown.Item
                                eventKey="6"
                                onClick={() => handleRaiseHand(!isRaise)}
                                className={classes.settingItem}
                            >
                                <PanToolIcon />{" "}
                                {intl.formatMessage({
                                    id: "TOOLBOX.SETTING.RAISE_HAND"
                                })}
                            </Dropdown.Item>
                        )}

                        <Dropdown.Item
                            eventKey="7"
                            onClick={() => handleRecordDig(!isShowRecordDlg)}
                            // className={classes.settingItem}
                        >
                            {props.isRecording ? (
                                <>
                                    <StopIcon />{" "}
                                    {intl.formatMessage({
                                        id: "TOOLBOX.SETTING.STOP_RECORD"
                                    })}
                                </>
                            ) : (
                                <>
                                    <RadioButtonCheckedIcon />{" "}
                                    {intl.formatMessage({
                                        id: "TOOLBOX.SETTING.START_RECORD"
                                    })}
                                </>
                            )}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    const props = {
        user: state.auth.user,
        lobbyMembers: state.event.lobbyMembers,
        isMicOn: state.event.isMicOn,
        isMuted: state.event.isMuted,
        isVideoOn: state.event.isVideoOn,
        cameraDevices: state.event.cameraDevices,
        isChatBoxOpen: state.event.isChatBoxOpen,
        isNewMsg: state.event.isNewMsg,
        isRaise: state.event.isRaise,
        isShowRecordDlg: state.event.isShowRecordDlg,
        isRecording: state.event.isRecording,
        isMosaic: state.event.isMosaic,
        isCamera: state.event.isCamera,
        isShareOtherCamera: state.event.isShareOtherCamera,
        isOscilloMeter: state.event.isOscilloMeter,
        isToolbarShow: state.event.isToolbarShow,
        isSubTitle: state.event.isSubTitle
    };

    if (state.auth.user.role <= UserRole.EVENT_MANAGER) {
        const adminProps = {
            isAdmin: true,
            isMuteAll: state.adminReducer.isMuteAll
        };
        return {
            ...props,
            ...adminProps
        };
    }

    return props;
};

const mapDispatchToProps = dispatch => {
    const actions = {
        endMeeting: () => dispatch(eventStore.actions.endMeeting()),
        openChatBox: isChatBoxOpen => dispatch(eventStore.actions.openChatBox(isChatBoxOpen)),
        setOpenAudioInputSettingDlg: isOpen =>
            dispatch(eventStore.actions.setOpenAudioInputSettingDlg(isOpen)),
        setOpenAudioOutputSettingDlg: isOpen =>
            dispatch(eventStore.actions.setOpenAudioOutputSettingDlg(isOpen)),
        setOpenVideoSettingDlg: isOpen =>
            dispatch(eventStore.actions.setOpenVideoSettingDlg(isOpen)),
        setIsRaise: isRaise => dispatch(eventStore.actions.setIsRaise(isRaise)),
        showRecordDlg: isShowRecordDlg =>
            dispatch(eventStore.actions.showRecordDlg(isShowRecordDlg)),
        updateScreenMode: mode => dispatch(eventStore.actions.updateScreenMode(mode)),
        updateIsMosaic: isMosaic => dispatch(eventStore.actions.updateIsMosaic(isMosaic)),
        setMuteAll: isMuteAll => dispatch(eventsStore.actions.setMuteAll(isMuteAll)),
        setIsOscilloMeter: isOscilloMeter =>
            dispatch(eventStore.actions.setIsOscilloMeter(isOscilloMeter))
    };
    return actions;
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Toolbar));
