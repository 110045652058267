import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import objectPath from "object-path";
import HMenuItem from "../../../../../_metronic/layout/header/HMenu/HMenuItem";
import * as builder from "../../../../../_metronic/ducks/builder";
import { toAbsoluteUrl } from "../../../../../_metronic";
import Topbar from "../../../../../_metronic/layout/header/Topbar";
import GroupTranslator from "./GroupTranslator";
import Sidebar from "../../../../components/Sidebar";

class InterSidebar extends React.Component {
    ktMenuCommonRef = React.createRef();

    constructor(props) {
        super(props);

        this.handleCloseClick = this.handleCloseClick.bind(this);
    }

    getHeaderLogo() {
        let result = "logo-md.png";
        if (this.props.headerSelfSkin && this.props.headerSelfSkin !== "dark") {
            result = "logo-md.png";
        }
        return toAbsoluteUrl(`/media/logos/${result}`);
    }

    get currentUrl() {
        return this.props.location.pathname.split(/[?#]/)[0];
    }

    handleCloseClick() {
        this.props.setShowInfoPanel(false);
    }

    render() {
        const {
            disabledAsideSelfDisplay,
            ktMenuClasses,
            ulClasses,
            rootArrowEnabled,
            event,
            showInfoPanel,
            setShowInfoPanel
        } = this.props;
        const items = this.props.menuConfig.header.items;
        return (
            <Sidebar showInfoPanel={showInfoPanel} setShowInfoPanel={setShowInfoPanel}>
                <Topbar />
                <Grid xs={12} item>
                    <div
                        id="kt_header_menu"
                        className={`kt-header-menu kt-header-menu-mobile ${ktMenuClasses}`}
                        ref={this.ktMenuCommonRef}
                        style={{ margin: 0, justifyContent: "center", display: "flex" }}
                    >
                        <ul className={`kt-menu__nav ${ulClasses}`}>
                            {items.map((item, index) => {
                                return (
                                    <React.Fragment key={`hmenuList${index}`}>
                                        {item.title && (
                                            <HMenuItem
                                                item={item}
                                                currentUrl={this.currentUrl}
                                                rootArrowEnabled={rootArrowEnabled}
                                            />
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </ul>
                    </div>
                </Grid>
                {disabledAsideSelfDisplay && (
                    <div className="kt-header-logo">
                        <Link to="/">
                            <img alt="logo" src={this.getHeaderLogo()} />
                        </Link>
                    </div>
                )}

                <Grid xs={12} item style={{ alignSelf: "center", marginTop: 8 }}>
                    <Typography align="center" variant="h6">
                        {event.title}
                    </Typography>
                </Grid>
                <Grid xs={12} item style={{ alignSelf: "center", marginBottom: 16 }}>
                    <Typography align="center" variant="subtitle1">
                        {event.detail}
                    </Typography>
                </Grid>

                <GroupTranslator />
            </Sidebar>
        );
    }
}

const mapStateToProps = store => ({
    config: store.builder.layoutConfig,
    menuConfig: store.builder.menuConfig,
    ktMenuClasses: builder.selectors.getClasses(store, {
        path: "header_menu",
        toString: true
    }),
    rootArrowEnabled: builder.selectors.getConfig(store, "header.menu.self.root-arrow"),
    headerSelfSkin: builder.selectors.getConfig(store, "header.self.skin"),
    ulClasses: builder.selectors.getClasses(store, {
        path: "header_menu_nav",
        toString: true
    }),
    disabledAsideSelfDisplay:
        objectPath.get(store.builder.layoutConfig, "aside.self.display") === false
});

export default withRouter(connect(mapStateToProps)(InterSidebar));
