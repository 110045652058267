import React from "react";
import {
    Grid,
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    makeStyles
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import BlockIcon from "@material-ui/icons/Block";
import { validMembers } from "./utils/RoomUtils";

const useStyles = makeStyles(theme => ({
    lobbyTitle: {
        margin: theme.spacing(3, 2, 0)
    }
}));

function ALobby(props) {
    const { lobbyMembers, handleAdmit, handleBlock } = props;
    const classes = useStyles();
    const _validMembers = validMembers(lobbyMembers);

    return (
        <Grid item xs={12}>
            <Typography variant="body1" component="div" className={classes.lobbyTitle}>
                <Box textAlign="center" fontWeight={500}>
                    Lobby({_validMembers.length})
                </Box>
            </Typography>

            <List dense={true}>
                {_validMembers.map(p => {
                    return (
                        <ListItem key={p.jid}>
                            <ListItemText primary={p.nick} />
                            <ListItemSecondaryAction>
                                <IconButton
                                    edge="end"
                                    aria-label="admit"
                                    onClick={() => handleAdmit(p.id)}
                                >
                                    <CheckBoxIcon />
                                </IconButton>
                                <IconButton
                                    edge="end"
                                    aria-label="block"
                                    onClick={() => handleBlock(p.id)}
                                >
                                    <BlockIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })}
            </List>
        </Grid>
    );
}

export default ALobby;
