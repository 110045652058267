import React, { useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { CircularProgress } from "@material-ui/core";
import Chat from "../../../../../components/Chat1";
import ToolBar from "../../../../../components/AToolbar";
import RemoteVideo from "../../../../../components/RemoteVideo";
import { toAbsoluteUrl } from "../../../../../../_metronic";
import { calcSidePlaceHeight } from "../../../../../components/utils/VideoLayoutUtils";
import SelectDeviceDialog from "../../../../../components/SelectDeviceDialog";
import VideoTrack from "../../../../../components/VideoTrack";
import { meetingVideo as useStyles } from "../../../../../components/CommonStyles-new";

import * as eventStore from "../../../../../store/ducks/event.duck";
import * as messageStore from "../../../../../store/ducks/messages.duck";
import useHandleMosaicView from "../../../../../components/useHandleMosaicView";
import useHandleMuteOthers from "../../../../../components/useHandleMuteOthers";
import { UserRole } from "../../../../../components/utils/UserRole";
import MeetContainer from "../../../../../components/MeetContainer";
import { EventType } from "../../../../../components/utils/EventType";

import { jitsiMeeting } from "../../../../../components/JitsiMeeting";
import AudioPlace from "../../../../../components/AudioPlace";
import useHandleMute from "../../../../../components/useHandleMute";
import { API_STATUS } from "../../../../../crud/helper";
import Sidebar from "../../../../../components/Sidebar";
import ALobby from "../../../../../components/ALobby";
import RecordingDialog from "../../../../../components/RecordingDialog";
import DivToCanvasStream from "../../../../../components/DivToCanvasStream";

function MeetingVideo(props) {
    const {
        event,
        room,
        isJoining,
        isEndMeeting,
        roomname,
        user,
        isLoadingToken,
        authToken,
        addStat,
        startStatId,
        isMosaic,
        screenMode,
        isMuted,
        openChatBox,
        isChatBoxOpen,
        openAudioOutputSettingDlg,
        setOpenAudioOutputSettingDlg,
        openVideoSettingDlg,
        setOpenVideoSettingDlg,
        openAudioInputSettingDlg,
        setOpenAudioInputSettingDlg,
        isRecording,
        isShowRecordDlg,
        showRecordDlg,
        cameraSetting,
        audioOutputSetting,
        audioInputSetting,
        focusedId,
        cameraDevices,
        audioOutputDevices,
        audioInputDevices,
        isMuteAll,
        participants,
        lobbyMembers,
        setParticipants,
        messageList,
        getMessages,
        toParticipant,
        setToParticipant,
        audioLevels,
        localTracks,
        isOriginal,
        audioTracks,
        isSubTitle,
        isRaise,
        roomsList,
        intl
    } = props;

    const [showInfoPanel, setShowInfoPanel] = React.useState(false);
    const classes = useStyles({ ...props, showInfoPanel });

    useEffect(() => {
        if (isEndMeeting) {
            unload();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEndMeeting]);

    useEffect(() => {
        console.log("🚀 ~ useEffect ~ authToken:", authToken);
        console.log("🚀 ~ useEffect ~ isLoadingToken:", isLoadingToken);
        if (isLoadingToken == API_STATUS.SUCCESS && authToken) {
            startJitsiMeeting();
        }
    }, [isLoadingToken, authToken]);

    useEffect(() => {
        getMessages();

        calcSidePlaceHeight();
        return () => {
            if (jitsiMeeting.connection) {
                unload();
            }
        };
    }, []);

    useEffect(() => {
        if (isJoining === 1) {
            // handleRoomJoined();
        }
    }, [isJoining]);

    useEffect(() => {
        if (jitsiMeeting.room) {
            postState(3, roomname);
            jitsiMeeting.changeLangTrack(roomname);
        }
    }, [roomname, room]);

    useEffect(() => {
        jitsiMeeting.setIsWithFloor(isOriginal);
    }, [isOriginal]);

    // useHandleMute(isMuted);

    useHandleMosaicView(isMosaic, screenMode, participants, isSubTitle);

    // useHandleMuteOthers(isMuteAll);

    useEffect(() => {
        jitsiMeeting.handleRaiseHand(isRaise);
    }, [isRaise]);

    async function startJitsiMeeting() {
        if (!event) {
            return;
        }

        const appId = process.env.REACT_APP_APP_ID;
        jitsiMeeting.connect(appId, authToken, event, roomname, {
            isMicOn: true,
            isMuted: true
        });
    }

    function allowUser(participantId) {
        const participant = jitsiMeeting.room.getParticipantById(participantId);
        if (participant) {
            if (parseInt(participant.getProperty("role")) === UserRole.OBSERVER) {
                jitsiMeeting.room.sendCommandOnce("moderator", {
                    value: participantId,
                    attributes: {
                        actionType: "allow",
                        content: 1
                    }
                });
            } else {
                jitsiMeeting.room.sendCommandOnce("moderator", {
                    value: participantId,
                    attributes: {
                        actionType: "allow",
                        content: 2
                    }
                });
            }
        }
    }

    // async function onLobbyUserJoined(id, data) {
    //     console.info(id, data);
    //     // Automatically approve lobby user
    //     await jitsiMeeting.room.lobbyApproveAccess(id);
    // }

    // function onLobbyUserUpdated(id, aa) {
    //     console.info(id, aa);
    // }

    // function onMembersOnlyChanged(status) {
    //     console.info(status);
    // }

    function sendPreMsg(participantId, msg) {
        console.info(participantId, msg);
        jitsiMeeting.room.sendCommandOnce("moderator", {
            value: participantId,
            attributes: {
                actionType: "notify",
                content: "msg",
                ...msg
            }
        });
    }

    function renderRemoteParticipant(p) {
        const pId = p.getId();
        const displayName = p.getDisplayName();

        const videoTrack = jitsiMeeting.remoteTracks[pId].find(
            t => t.getType() === "video" && !t.disposed
        );

        let videoElement = "";
        let placeClass = classes.participantContainer;

        // Focus Element
        if (
            !(event.event_type === EventType.CONFERENCE || event.event_type === EventType.WEB) &&
            pId === focusedId
        ) {
            placeClass += ` ${classes.participantContainerFocused}`;
        }

        let _isMuted = true;
        _isMuted = !!jitsiMeeting.remoteTracks[pId].find(t => t.getType() === "audio" && t.muted);

        if (jitsiMeeting.remoteTracks[pId]) {
            if (parseInt(p.getProperty("role")) === UserRole.INTERPRETER) {
                placeClass += " interpreterPlace";
            }

            if (parseInt(p.getProperty("role")) !== UserRole.OBSERVER) {
                if (videoTrack) {
                    videoElement = (
                        <VideoTrack
                            id={`${pId}video`}
                            className={classes.video}
                            muted={true}
                            videoTrack={{ jitsiTrack: videoTrack }}
                        />
                    );

                    jitsiMeeting.setReceiverConstraints(pId);
                }
            }
        }

        // Participant's output language
        const p_output = p.getProperty("output");
        const is_non = p_output ? p_output.includes("non-") : false;

        let output_lang = null;
        if (is_non) {
            output_lang = { lang_name: "ORI" };
        } else {
            const p_output_room = event.event_rooms.find(
                event_room => event_room.room.room_name === p_output
            );

            output_lang = p_output_room ? p_output_room.room.output_lang : null;
        }

        const isHand = !!p.getProperty("hand");

        return (
            <div
                key={pId}
                id={`${pId}Place`}
                className={placeClass}
                onClick={() => handleClickSidePlace(pId)}
            >
                <RemoteVideo
                    id={pId}
                    displayName={displayName}
                    allowUser={
                        event &&
                        event.event_type === EventType.WEBINAR &&
                        parseInt(p.getProperty("role")) >= UserRole.USER
                            ? allowUser
                            : null
                    }
                    baseClasses={classes}
                    muteParticipant={muteParticipant}
                    kickParticipant={kickParticipant}
                    openChatBox={openChatBox}
                    setToParticipant={setToParticipant}
                    messageList={messageList}
                    lang={intl.locale}
                    sendPreMsg={sendPreMsg}
                    isNotify={true}
                    isMicOn={!is_non}
                    isMuted={_isMuted}
                    audioLevels={audioLevels}
                    isMosaic={isMosaic}
                    output_lang={output_lang}
                    isHand={isHand}
                >
                    {videoElement}
                </RemoteVideo>
            </div>
        );
    }

    function handleAdmit(id) {
        console.log("Admit");
        jitsiMeeting.lobbyAccess(id, true);
    }

    function handleBlock(id) {
        console.log("Block");
        jitsiMeeting.lobbyAccess(id, false);
    }

    function unload() {
        jitsiMeeting.unload();
    }

    function postState(status, _roomname) {
        const currentRoom = event.event_rooms.filter(
            event_room => event_room.room.room_name === _roomname
        );
        const stat = {
            event_id: event.id,
            status: status,
            room_id: currentRoom.length > 0 ? currentRoom[0].room_id : 0,
            start_stat_id: startStatId ? startStatId : 0
        };
        addStat(stat);
    }

    function handleClickSidePlace(pId) {
        if (!jitsiMeeting.room) return;

        if (pId === "selfPlace") {
            jitsiMeeting.setFocusedId(jitsiMeeting.room.myUserId());
        } else {
            jitsiMeeting.setReceiverConstraints(pId);
            jitsiMeeting.setFocusedId(pId);
        }
        setParticipants(jitsiMeeting.room.getParticipants());
    }

    function _getBigVideoTrack() {
        let newBigVideoTrack = localTracks.find(t => t.getType() === "video");
        if (!jitsiMeeting.room || !focusedId) return newBigVideoTrack;

        if (focusedId === jitsiMeeting.room.myUserId()) {
            newBigVideoTrack = localTracks.find(t => t.getType() === "video");
        } else {
            const participant = jitsiMeeting.room.getParticipantById(focusedId);
            if (!participant) return null;

            if (participant.getProperty("role") !== UserRole.OBSERVER) {
            }

            if (participant.getProperty("role") === UserRole.INTERPRETER) {
            }

            const tracks = participant.getTracks();
            if (tracks.find(t => t.getType() === "video")) {
                newBigVideoTrack = tracks.find(t => t.getType() === "video");
            } else {
                newBigVideoTrack = null;
            }
        }

        return newBigVideoTrack;
    }

    function handleHoverEvent(event) {}

    function handleLeaveEvent(event) {}

    /**
     * Handle CameraSetting Change
     * @param {String} value
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    function handleVideoSettingChange(newCameraInput, isOpen = true) {
        if (isOpen) {
            jitsiMeeting.setCameraSetting(newCameraInput);
        }
        setOpenVideoSettingDlg(isOpen);
    }

    /**
     * Handle AudioOutputSetting Change
     * @param {String} newAudioOutput
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    function handleAudioOutputSettingChange(newAudioOutput, isOpen = true) {
        if (isOpen) {
            jitsiMeeting.setAudioOutputSetting(newAudioOutput);
        }
        setOpenAudioOutputSettingDlg(isOpen);
    }

    /**
     * Handle AudioInputSetting Change
     * @param {String} newAudioInput
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    function handleAudioInputSettingChange(newAudioInput, isOpen = true) {
        if (isOpen) {
            jitsiMeeting.setAudioInputSetting(newAudioInput);
        }
        setOpenAudioInputSettingDlg(isOpen);
    }

    function startRecording(options, isOpen = true) {
        console.log("🚀 ~ startRecording ~ options:", options);
        jitsiMeeting.startRecording({ roomsList, ...options });
        showRecordDlg(isOpen);
    }

    function stopRecording(options, isOpen = true) {
        console.log("🚀 ~ startRecording ~ options:", options);
        jitsiMeeting.stopRecording();
        showRecordDlg(isOpen);
    }

    /**
     * This function is called to mute a participant.
     * @param {*} id
     */
    function muteParticipant(id) {
        jitsiMeeting.room.muteParticipant(id);
    }

    /**
     * This function is called to kick participant.
     * @param {*} id
     */
    function kickParticipant(id) {
        console.info(jitsiMeeting.room.getRole(), id);
        if (toParticipant === id) {
            setToParticipant("all");
        }
        console.log(jitsiMeeting.room);
        jitsiMeeting.room.kickParticipant(id);
    }

    function sendTextMessage(id, message) {
        if (id === "all") {
            jitsiMeeting.room.sendTextMessage(message);
        } else {
            jitsiMeeting.room.sendPrivateTextMessage(id, message);
        }
    }

    function validUsers(p) {
        const role = parseInt(p.getProperty("role"));
        return role > 0 && role !== UserRole.LITE_USER;
    }

    return (
        <>
            <MeetContainer className="row">
                <div className="col-md-12">
                    <div
                        id="localPlace"
                        className={classes.localPlace}
                        onMouseEnter={handleHoverEvent}
                        onMouseLeave={handleLeaveEvent}
                    >
                        {isJoining == 2 ? (
                            <div className={classes.fullLayout}>
                                <div className={classes.mainWrapper}>
                                    <div className={classes.avatar}>
                                        <img
                                            src={
                                                event.pic
                                                    ? process.env.REACT_APP_FILE_URL + event.pic
                                                    : toAbsoluteUrl("/media/logos/logo-trans.png")
                                            }
                                            alt="avatar"
                                        />
                                    </div>

                                    {isRecording && (
                                        <div className={classes.meetingStatus}>Recording</div>
                                    )}

                                    <div className={classes.mainPlaceWrapper}>
                                        <div className={classes.mainPanel}>
                                            <div className={classes.bigVideoWrapper}>
                                                <VideoTrack
                                                    id="bigVideo"
                                                    className={classes.bigVideo}
                                                    muted={true}
                                                    videoTrack={{ jitsiTrack: _getBigVideoTrack() }}
                                                />
                                            </div>

                                            <div
                                                className={
                                                    isMosaic
                                                        ? classes.mosaicOverlay
                                                        : classes.hideSelfVideo
                                                }
                                            />
                                            <div
                                                id="sidePlace"
                                                className={
                                                    isMosaic
                                                        ? classes.mosaicRemoteContainer
                                                        : classes.sidePlace
                                                }
                                            >
                                                <div className="sideWrapper">
                                                    <div
                                                        id="remotePlace"
                                                        className={classes.remotePlace}
                                                    >
                                                        <div
                                                            id="selfPlace"
                                                            className={`${
                                                                classes.participantContainer
                                                            } ${
                                                                jitsiMeeting.room &&
                                                                focusedId ===
                                                                    jitsiMeeting.room.myUserId()
                                                                    ? classes.participantContainerFocused
                                                                    : ""
                                                            }`}
                                                            onClick={() =>
                                                                handleClickSidePlace("selfPlace")
                                                            }
                                                        >
                                                            <div className={classes.videoContainer}>
                                                                <VideoTrack
                                                                    id="selfVideo"
                                                                    className={classes.video}
                                                                    muted={true}
                                                                    videoTrack={{
                                                                        jitsiTrack: localTracks.find(
                                                                            track =>
                                                                                track.getType() ===
                                                                                "video"
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="displayName">
                                                                {user.name}(
                                                                {intl.formatMessage({
                                                                    id: "CHAT.ME"
                                                                })}
                                                                )
                                                            </div>
                                                            {isRaise && (
                                                                <div className={classes.hand}>
                                                                    <i className="fas fa-hand-paper"></i>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {participants
                                                            .filter(p => validUsers(p))
                                                            .map(p => renderRemoteParticipant(p))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.sidePanel}>
                                            <Sidebar
                                                showInfoPanel={showInfoPanel}
                                                setShowInfoPanel={setShowInfoPanel}
                                            >
                                                <ALobby
                                                    lobbyMembers={lobbyMembers}
                                                    handleAdmit={handleAdmit}
                                                    handleBlock={handleBlock}
                                                />
                                            </Sidebar>
                                        </div>
                                    </div>

                                    {Object.keys(audioTracks).map(key => (
                                        <AudioPlace
                                            id={`${key}audio`}
                                            key={key}
                                            audioTrack={{
                                                jitsiTrack: audioTracks[key].audioTrack
                                            }}
                                            participantId={key}
                                            _muted={!!audioTracks[key].muted}
                                            _volume={
                                                audioTracks[key].volume
                                                    ? audioTracks[key].volume
                                                    : 0
                                            }
                                        />
                                    ))}
                                    <div id="chatContainer" className={classes.chatContainer}>
                                        <Chat
                                            open={isChatBoxOpen}
                                            setIsChatDlg={openChatBox}
                                            sendTextMessage={sendTextMessage}
                                            participants={participants}
                                        />
                                    </div>

                                    <div>
                                        {isSubTitle && (
                                            <div
                                                id="subTitleWrapper"
                                                className={classes.subTitleWrapper}
                                            >
                                                <div id="subTitle" className={classes.subTitle}>
                                                    {event.subtitle && event.subtitle !== "" && (
                                                        <iframe
                                                            title={event.title}
                                                            src={event.subtitle}
                                                            className={classes.subTitleIframe}
                                                        ></iframe>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        <ToolBar
                                            event={event}
                                            showInfoPanel={showInfoPanel}
                                            updateShowInfoPanel={setShowInfoPanel}
                                        />
                                    </div>

                                    {/* <DivToCanvasStream /> */}

                                    {/* Camera Setting Dialog */}
                                    <SelectDeviceDialog
                                        id="cameraSetting"
                                        title={intl.formatMessage({
                                            id: "VIDEO.SETTING.CAMERA.TITLE"
                                        })}
                                        label={intl.formatMessage({
                                            id: "VIDEO.SETTING.CAMERA"
                                        })}
                                        isOpen={openVideoSettingDlg}
                                        devices={cameraDevices}
                                        currentValue={cameraSetting}
                                        onChange={handleVideoSettingChange}
                                    />

                                    {/* Audio Output Setting Dialog */}
                                    <SelectDeviceDialog
                                        id="audioOutputSetting"
                                        title={intl.formatMessage({
                                            id: "VIDEO.SETTING.AUDIO_OUPUTS.TITLE"
                                        })}
                                        label={intl.formatMessage({
                                            id: "VIDEO.SETTING.AUDIO_OUPUTS"
                                        })}
                                        isOpen={openAudioOutputSettingDlg}
                                        devices={audioOutputDevices}
                                        currentValue={audioOutputSetting}
                                        onChange={handleAudioOutputSettingChange}
                                    />

                                    {/* Audio Input Setting Dialog */}
                                    <SelectDeviceDialog
                                        id="audioInputSetting"
                                        title={intl.formatMessage({
                                            id: "VIDEO.SETTING.AUDIO_INPUTS_TITLE"
                                        })}
                                        label={intl.formatMessage({
                                            id: "VIDEO.SETTING.AUDIO_INPUTS"
                                        })}
                                        isOpen={openAudioInputSettingDlg}
                                        devices={audioInputDevices}
                                        currentValue={audioInputSetting}
                                        onChange={handleAudioInputSettingChange}
                                    />
                                    {/* Recording Dialog */}
                                    <RecordingDialog
                                        id="recordingSetting"
                                        title={
                                            isRecording
                                                ? intl.formatMessage({
                                                      id: "DLG.RECORDING.STOP_TITLE"
                                                  })
                                                : intl.formatMessage({
                                                      id: "DLG.RECORDING.START_TITLE"
                                                  })
                                        }
                                        label={intl.formatMessage({
                                            id: "VIDEO.SETTING.AUDIO_INPUTS"
                                        })}
                                        isRecording={isRecording}
                                        isOpen={isShowRecordDlg}
                                        onChange={isRecording ? stopRecording : startRecording}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className={classes.loadingSection}>
                                <CircularProgress size={24} className={""} />
                                <span style={{ margin: 10 }}>
                                    {isLoadingToken == API_STATUS.ERROR
                                        ? "Can't connect to the meeting. Please try again."
                                        : "Connecting..."}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </MeetContainer>
        </>
    );
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        authToken: state.event.authToken,
        isLoadingToken: state.event.isLoadingToken,
        isEndMeeting: state.event.isEndMeeting,
        startStatId: state.event.startStatId,
        room: state.event.room,
        isJoining: state.event.isJoining,
        isMuted: state.event.isMuted,
        isVideoOn: state.event.isVideoOn,
        cameraSetting: state.event.cameraSetting,
        audioOutputSetting: state.event.audioOutputSetting,
        audioInputSetting: state.event.audioInputSetting,
        cameraDevices: state.event.cameraDevices,
        audioOutputDevices: state.event.audioOutputDevices,
        audioInputDevices: state.event.audioInputDevices,
        isChatBoxOpen: state.event.isChatBoxOpen,
        openVideoSettingDlg: state.event.openVideoSettingDlg,
        openAudioOutputSettingDlg: state.event.openAudioOutputSettingDlg,
        openAudioInputSettingDlg: state.event.openAudioInputSettingDlg,
        isShowRecordDlg: state.event.isShowRecordDlg,
        isRecording: state.event.isRecording,
        isRaise: state.event.isRaise,
        isMosaic: state.event.isMosaic,
        screenMode: state.event.screenMode,
        isCamera: state.event.isCamera,
        isShareOtherCamera: state.event.isShareOtherCamera,
        isMuteAll: state.adminReducer.isMuteAll,
        participants: state.event.participants,
        lobbyMembers: state.event.lobbyMembers,
        toParticipant: state.event.toParticipant,
        messageList: state.messages.messageList,
        isSidePlaceShow: state.event.isSidePlaceShow,
        audioLevels: state.event.audioLevels,
        localTracks: state.event.localTracks,
        focusedId: state.event.focusedId,
        audioTracks: state.event.audioTracks,
        isSubTitle: state.event.isSubTitle
    };
};

const mapDispatchToProps = dispatch => ({
    addStat: data => dispatch(eventStore.actions.addStat(data)),
    openChatBox: isChatBoxOpen => dispatch(eventStore.actions.openChatBox(isChatBoxOpen)),
    setOpenAudioInputSettingDlg: isOpen =>
        dispatch(eventStore.actions.setOpenAudioInputSettingDlg(isOpen)),
    setOpenAudioOutputSettingDlg: isOpen =>
        dispatch(eventStore.actions.setOpenAudioOutputSettingDlg(isOpen)),
    showRecordDlg: isOpen => dispatch(eventStore.actions.showRecordDlg(isOpen)),
    setOpenVideoSettingDlg: isOpen => dispatch(eventStore.actions.setOpenVideoSettingDlg(isOpen)),
    setParticipants: participants => dispatch(eventStore.actions.setParticipants(participants)),
    setToParticipant: toParticipant => dispatch(eventStore.actions.setToParticipant(toParticipant)),
    getMessages: () => dispatch(messageStore.actions.getMessages())
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MeetingVideo));
