import React, { useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import ToolBar from "../../../../components/Toolbar";
import SelectDeviceDialog from "../../../../components/SelectDeviceDialog";
import { toAbsoluteUrl } from "../../../../../_metronic";
import { meetingVideo as useStyles } from "../../../../components/CommonStyles";
import { jitsiMeeting } from "../../../../components/JitsiMeeting";
import VideoTrack from "../../../../components/VideoTrack";

import * as eventStore from "../../../../store/ducks/event.duck";
import MeetContainer from "../../../../components/MeetContainer";

function Video(props) {
    const {
        event,
        isEndMeeting,
        cameraDevices,
        audioOutputDevices,
        audioInputDevices,
        cameraSetting,
        audioOutputSetting,
        audioInputSetting,
        openAudioOutputSettingDlg,
        setOpenAudioOutputSettingDlg,
        openVideoSettingDlg,
        setOpenVideoSettingDlg,
        openAudioInputSettingDlg,
        setOpenAudioInputSettingDlg,
        localTracks,
        intl
    } = props;

    const classes = useStyles(props);

    useEffect(() => {
        if (isEndMeeting) {
            unload();
        } else {
            startJitsiMeeting();
        }

        return () => {
            if (jitsiMeeting.connection) {
                unload();
            }
        };
    }, [isEndMeeting]);

    function startJitsiMeeting() {
        if (!event) {
            return;
        }

        const appId = process.env.REACT_APP_APP_ID;
        jitsiMeeting.connect(appId, null, event, null, {
            isMicOn: true,
            isMuted: true
        });
    }

    async function unload() {
        jitsiMeeting.unload();
    }

    // TODO:
    function handleHoverEvent(event) {}

    // TODO:
    function handleLeaveEvent(event) {}

    function _getBigVideoTrack() {
        let newBigVideoTrack = localTracks.find(t => t.getType() === "video");

        return newBigVideoTrack;
    }

    /**
     * Handle CameraSetting Change
     * @param {String} value
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    function handleVideoSettingChange(newCameraInput, isOpen = true) {
        if (isOpen) {
            jitsiMeeting.setCameraSetting(newCameraInput);
        }
        setOpenVideoSettingDlg(isOpen);
    }

    /**
     * Handle AudioOutputSetting Change
     * @param {String} value
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    function handleAudioOutputSettingChange(newAudioOutput, isOpen = true) {
        if (isOpen) {
            jitsiMeeting.setAudioOutputSetting(newAudioOutput);
        }
        setOpenAudioOutputSettingDlg(isOpen);
    }

    /**
     * Handle AudioInputSetting Change
     * @param {String} value
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    async function handleAudioInputSettingChange(newAudioInput, isOpen = true) {
        if (isOpen) {
            jitsiMeeting.setAudioInputSetting(newAudioInput);
        }
        setOpenAudioInputSettingDlg(isOpen);
    }

    return (
        <>
            <MeetContainer className="row">
                <div className="col-md-12">
                    <div
                        id="localPlace"
                        className={classes.localPlace}
                        onMouseEnter={handleHoverEvent}
                        onMouseLeave={handleLeaveEvent}
                    >
                        <div className={classes.avatar}>
                            <img
                                src={
                                    event.pic
                                        ? process.env.REACT_APP_FILE_URL + event.pic
                                        : toAbsoluteUrl("/media/logos/logo-trans.png")
                                }
                                alt="avatar"
                            />
                        </div>

                        <ToolBar event={event} disableChat={true} />

                        <VideoTrack
                            id="bigVideo"
                            className={`${classes.bigVideoFullWidth} ${classes.bigVideo}`}
                            muted={true}
                            videoTrack={{ jitsiTrack: _getBigVideoTrack() }}
                        />

                        {/* Camera Setting Dialog */}
                        <SelectDeviceDialog
                            id="cameraSetting"
                            title={intl.formatMessage({
                                id: "VIDEO.SETTING.CAMERA.TITLE"
                            })}
                            label={intl.formatMessage({
                                id: "VIDEO.SETTING.CAMERA"
                            })}
                            isOpen={openVideoSettingDlg}
                            devices={cameraDevices}
                            currentValue={cameraSetting}
                            onChange={handleVideoSettingChange}
                        />

                        {/* Audio Output Setting Dialog */}
                        <SelectDeviceDialog
                            id="audioOutputSetting"
                            title={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_OUPUTS.TITLE"
                            })}
                            label={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_OUPUTS"
                            })}
                            isOpen={openAudioOutputSettingDlg}
                            devices={audioOutputDevices}
                            currentValue={audioOutputSetting}
                            onChange={handleAudioOutputSettingChange}
                        />

                        {/* Audio Input Setting Dialog */}
                        <SelectDeviceDialog
                            id="audioInputSetting"
                            title={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_INPUTS_TITLE"
                            })}
                            label={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_INPUTS"
                            })}
                            isOpen={openAudioInputSettingDlg}
                            devices={audioInputDevices}
                            currentValue={audioInputSetting}
                            onChange={handleAudioInputSettingChange}
                        />
                    </div>
                </div>
            </MeetContainer>
        </>
    );
}

const mapStateToProps = state => {
    return {
        isEndMeeting: state.event.isEndMeeting,
        startStatId: state.event.startStatId,
        cameraSetting: state.event.cameraSetting,
        audioOutputSetting: state.event.audioOutputSetting,
        audioInputSetting: state.event.audioInputSetting,
        cameraDevices: state.event.cameraDevices,
        audioOutputDevices: state.event.audioOutputDevices,
        audioInputDevices: state.event.audioInputDevices,
        openVideoSettingDlg: state.event.openVideoSettingDlg,
        openAudioOutputSettingDlg: state.event.openAudioOutputSettingDlg,
        openAudioInputSettingDlg: state.event.openAudioInputSettingDlg,
        localTracks: state.event.localTracks
    };
};

const mapDispatchToProps = dispatch => ({
    addStat: data => dispatch(eventStore.actions.addStat(data)),
    setOpenAudioInputSettingDlg: isOpen =>
        dispatch(eventStore.actions.setOpenAudioInputSettingDlg(isOpen)),
    setOpenAudioOutputSettingDlg: isOpen =>
        dispatch(eventStore.actions.setOpenAudioOutputSettingDlg(isOpen)),
    setOpenVideoSettingDlg: isOpen => dispatch(eventStore.actions.setOpenVideoSettingDlg(isOpen)),
    setIsCamera: isCamera => dispatch(eventStore.actions.setIsCamera(isCamera))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Video));
