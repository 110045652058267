import React, { useRef, useEffect } from "react";
import { Grid } from "@material-ui/core";
import html2canvas from "html2canvas";

function MeetContainer(props) {
    const { children } = props;
    const divRef = useRef(null);
    const canvasRef = useRef(null);
    const animationRef = useRef(null);
    const lastCapturedTime = useRef(Date.now());
    const THROTTLE_INTERVAL = 500; // in milliseconds
    // useEffect(() => {
    //     // Function to capture the div and draw it on the canvas
    //     const captureDivToCanvas = () => {
    //         const currentTime = Date.now();

    //         if (currentTime - lastCapturedTime.current >= THROTTLE_INTERVAL) {
    //             const div = divRef.current;
    //             const canvas = canvasRef.current;

    //             if (div && canvas) {
    //                 html2canvas(div).then(canvasElement => {
    //                     const ctx = canvas.getContext("2d");
    //                     ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear previous content
    //                     ctx.drawImage(canvasElement, 0, 0, canvas.width, canvas.height);
    //                 });
    //             }

    //             lastCapturedTime.current = currentTime;
    //         }

    //         // Repeat the capture using requestAnimationFrame
    //         animationRef.current = requestAnimationFrame(captureDivToCanvas);
    //     };

    //     // Start the animation frame loop
    //     captureDivToCanvas();

    //     // Clean up on component unmount
    //     return () => {
    //         cancelAnimationFrame(animationRef.current);
    //     };
    // }, []);
    const onMouseOver = () => {
        // dispatch(eventStore.actions.showToolbox())
    };

    return (
        <>
            <Grid
                id="meet"
                ref={divRef}
                className={props.className ? props.className : ""}
                onMouseOver={onMouseOver}
            >
                {children}
            </Grid>

            {/* <canvas ref={canvasRef} width="320" height="240" /> */}
        </>
    );
}

export default MeetContainer;
