import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    Switch,
    MenuItem,
    Box,
    Grid
} from "@material-ui/core";
import { injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content"
    },
    formControl: {
        minWidth: 120
    }
}));

function RecordingDialog(props) {
    const { id, title, isOpen, onChange, intl, isRecording } = props;
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const [state, setState] = React.useState({
        video: false,
        floor: true,
        languages: true
    });

    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.checked });
    };

    useEffect(() => {
        if (isOpen) setOpen(isOpen);
    }, [isOpen]);

    // Handle Cancel
    function handleCancel() {
        // onChange(currentValue, false);
        setOpen(false);
    }

    // Handle Okay
    function handleOk(value) {
        onChange(value, false);
        setOpen(false);
    }

    function handleClose(event, reason) {
        console.log(event);
        console.log(reason);
    }

    return (
        <>
            <Dialog
                disableEscapeKeyDown
                maxWidth="xs"
                fullWidth={true}
                aria-labelledby="confirmation-dialog-title"
                open={open}
                disablePortal
                style={{ position: "absolute" }}
                onClose={handleClose}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <form className={classes.form} noValidate>
                        <FormControl className={classes.formControl}>
                            {isRecording ? (
                                <Box>Are you sure you would like to stop the recording? </Box>
                            ) : (
                                <>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Box>Record video</Box>
                                        <Switch
                                            checked={state.video}
                                            onChange={handleChange("video")}
                                            value="video"
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Box>Record floor</Box>
                                        <Switch
                                            checked={state.floor}
                                            onChange={handleChange("floor")}
                                            value="floor"
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Box>Record languages</Box>
                                        <Switch
                                            checked={state.languages}
                                            onChange={handleChange("languages")}
                                            value="languages"
                                        />
                                    </Grid>
                                </>
                            )}
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        {intl.formatMessage({ id: "DLG.RECORDING.BTN_CANCEL" })}
                    </Button>
                    <Button onClick={() => handleOk(state)} color="primary">
                        {isRecording
                            ? intl.formatMessage({ id: "DLG.RECORDING.BTN_CONFIRM" })
                            : intl.formatMessage({ id: "DLG.RECORDING.BTN_START" })}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

RecordingDialog.propTypes = {
    onChange: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired
};

export default injectIntl(RecordingDialog);
