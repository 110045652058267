export function getLocalTrack(tracks, mediaType, includePending = false) {
    return getLocalTracks(tracks, includePending).find(
        t => t.mediaType === mediaType || t.type === mediaType
    );
}

export function getLocalTracks(tracks, includePending = false) {
    // XXX A local track is considered ready only once it has its `jitsiTrack`
    // property set by the `TRACK_ADDED` action. Until then there is a stub
    // added just before the `getUserMedia` call with a cancellable
    // `gumInProgress` property which then can be used to destroy the track that
    // has not yet been added to the redux store. Once GUM is cancelled, it will
    // never make it to the store nor there will be any
    // `TRACK_ADDED`/`TRACK_REMOVED` actions dispatched for it.
    // return tracks.filter(t => t.local && (t.jitsiTrack || includePending));
    return tracks.filter(t => t.track || includePending);
}
