import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { makeStyles, Button, colors, IconButton, Fab } from "@material-ui/core";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import CallEndIcon from "@material-ui/icons/CallEnd";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SettingsVoiceIcon from "@material-ui/icons/SettingsVoice";
import SpeakerIcon from "@material-ui/icons/Speaker";
import MessageIcon from "@material-ui/icons/Message";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import GroupIcon from "@material-ui/icons/Group";
import SubtitlesIcon from "@material-ui/icons/Subtitles";
import { Dropdown } from "react-bootstrap";
import CustomMenu from "../../../../components/CustomMenu";
import CustomToggle from "../../../../components/CustomToggle";
import * as eventStore from "../../../../store/ducks/event.duck";
import * as eventsStore from "../../../../store/ducks/admins/index.duck";
import { jitsiMeeting, ORIGINAL_ROOMNAME } from "../../../../components/JitsiMeeting";
import ToggleButton from "../../../../components/ToggleButton";

const useStyles = makeStyles(theme => ({
    toolbox: {
        // position: "absolute",
        bottom: props => (props.isToolbarShow ? 0 : -56),
        left: "auto",
        right: "auto",
        zIndex: "500",
        width: "100%",
        // backgroundImage: "linear-gradient(to top,rgba(0,0,0,.6),rgba(0,0,0,0))",
        backgroundColor: "#525050",
        display: "flex",
        justifyContent: "center",
        transition: "bottom .3s ease-in",
        alignItems: "center",
        alignSelf: "center"
    },
    toolboxLeft: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        alignSelf: "center",
        width: "38%"
    },
    toolboxCenter: {
        display: "flex",
        // justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        width: "24%"
    },
    toolboxRight: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        alignSelf: "center",
        width: "38%"
    },
    toolboxRight_left: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        alignSelf: "center"
    },
    toolboxRight_right: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        alignSelf: "center"
    },
    shareScreen: {
        margin: 6,
        color: colors.common.white,
        minWidth: "40px !important",
        [theme.breakpoints.down("xs")]: {
            height: "30px !important",
            width: "30px !important",
            minWidth: "30px !important",
            minHeight: "30px !important",
            margin: 2
        }
    },
    call: {
        margin: theme.spacing(1),
        backgroundColor: colors.red[800],
        color: colors.common.white,
        minWidth: "40px !important",
        [theme.breakpoints.down("xs")]: {
            height: "30px !important",
            width: "30px !important",
            minWidth: "30px !important",
            minHeight: "30px !important",
            margin: 2
        },
        "&:hover": {
            color: colors.common.white,
            backgroundColor: colors.red[300]
        }
    },
    handleOver: {
        backgroundColor: props => (props.isHandOverReady ? colors.green[800] : colors.orange[800]),
        color: colors.common.white,
        padding: "0 !important"
    },
    "@keyframes blinker": {
        from: { backgroundColor: colors.yellow["A400"] },
        to: { backgroundColor: "#0abb87" }
    },
    chat: {
        animationName: "$blinker",
        animationDuration: "1s",
        animationTimingFunction: "linear",
        animationIterationCount: "infinite",
        "animation-fill-mode": "initial",
        "&:hover": {
            backgroundColor: colors.yellow[800]
        }
    },
    videoMute: {
        height: "40px !important",
        minWidth: "40px !important",
        borderRadius: "50% !important",
        [theme.breakpoints.down("xs")]: {
            height: "30px !important",
            width: "30px !important",
            minWidth: "30px !important",
            minHeight: "30px !important",
            margin: 2
        }
    },
    mosaicButton: {
        padding: 10
    },
    volume: {
        color: colors.common.white,
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 8,
            paddingRight: 8
        }
    },
    button: {
        color: colors.common.white,
        backgroundColor: colors.common.black,
        minWidth: 40,
        [theme.breakpoints.down("xs")]: {
            width: 40,
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    toolbarItem: {
        display: "block",
        [theme.breakpoints.down(1024)]: {
            display: "none"
        }
    },
    settingItem: {
        display: "none",
        [theme.breakpoints.down(1024)]: {
            display: "block"
        }
    }
}));

function Toolbar(props) {
    const {
        isMicOn,
        isMuted,
        isVideoOn,
        isChatBoxOpen,
        isNewMsg,
        isMosaic,
        disableVideo,
        isWithFloor,
        isSubTitle,
        inputRoomname,
        isHandOverReady,
        groupTranslator,
        showInfoPanel,
        intl
    } = props;
    const classes = useStyles(props);

    function handleVideoOn() {
        if (!disableVideo) jitsiMeeting.changeVideoOn(!isVideoOn);
    }

    function handleChat() {
        props.openChatBox(!isChatBoxOpen);
    }

    function handleLeave() {
        jitsiMeeting.unload();
    }

    function handleMosaicView() {
        props.updateIsMosaic(!isMosaic);
    }

    function handleVideoSetting() {
        props.setOpenVideoSettingDlg(true);
    }

    function handleAudioOutputSetting() {
        props.setOpenAudioOutputSettingDlg(true);
    }

    function handleAudioInputSetting(params) {
        props.setOpenAudioInputSettingDlg(true);
    }

    function handleReset() {
        localStorage.setItem("restart", 1);
        window.location.reload();
    }

    function handleEventInfo(value) {
        props.updateShowInfoPanel(value);
    }

    function handleHandOverReady() {
        for (let i = 0; i < groupTranslator.length; i++) {
            jitsiMeeting.setHandOverReady(!isHandOverReady, {
                participantId: groupTranslator[i].getId(),
                msg_en: isHandOverReady ? "Handover now" : "Ready for handover"
            });

            if (isHandOverReady) {
                jitsiMeeting.changeMicOn(!isMicOn);
            }
        }
    }

    return (
        <>
            <div id="mobile-toolbox" className={`${classes.toolbox} d-flex d-lg-none`}>
                <ToggleButton
                    selected={isWithFloor}
                    onChanged={value => {
                        jitsiMeeting.setIsWithFloor(value);
                    }}
                >
                    With Floor
                </ToggleButton>
                <ToggleButton
                    selected={isMicOn}
                    onChanged={value => {
                        jitsiMeeting.changeMicOn(value);
                    }}
                    className={classes.button}
                >
                    <span style={{ width: 120 }}>Mic</span>
                </ToggleButton>
                <ToggleButton
                    selected={isMuted}
                    onChanged={value => {
                        if (isMicOn) jitsiMeeting.changeMute(value);
                    }}
                    className={classes.button}
                >
                    {isMuted ? "Muted" : "Mute"}
                </ToggleButton>
                <Fab
                    color="secondary"
                    size="small"
                    aria-label="Chat"
                    className={`${classes.shareScreen} ${isNewMsg ? classes.chat : ""}`}
                    onClick={handleChat}
                >
                    <MessageIcon />
                </Fab>
                <Fab
                    color="inherit"
                    size="small"
                    aria-label="CallEnd"
                    className={classes.call}
                    onClick={handleLeave}
                >
                    <CallEndIcon />
                </Fab>

                <IconButton
                    color="primary"
                    aria-label="Layout"
                    className={`${classes.volume} ${classes.mosaicButton} ${classes.toolbarItem}`}
                    onClick={handleMosaicView}
                >
                    <ViewModuleIcon />
                </IconButton>
                <IconButton
                    color="primary"
                    onClick={() => handleEventInfo(!showInfoPanel)}
                    className={`${classes.volume} ${classes.mosaicButton}`}
                >
                    <GroupIcon />
                </IconButton>
                <Dropdown alignRight>
                    <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-basic"
                        drop={"up"}
                        navbar={false}
                    >
                        <IconButton color="primary" aria-label="More" className={classes.volume}>
                            <MoreVertIcon />
                        </IconButton>
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu} className="dropdown-menu-end">
                        <Dropdown.Item eventKey="1" onClick={handleVideoSetting}>
                            <VideocamIcon />{" "}
                            {intl.formatMessage({
                                id: "VIDEO.VIDEO_INPUT"
                            })}
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="3" onClick={handleAudioInputSetting}>
                            <SettingsVoiceIcon />{" "}
                            {intl.formatMessage({
                                id: "VIDEO.AUDIO_INPUT"
                            })}
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="2" onClick={handleAudioOutputSetting}>
                            <SpeakerIcon />{" "}
                            {intl.formatMessage({
                                id: "VIDEO.AUDIO_OUTPUT"
                            })}
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey="5"
                            onClick={handleMosaicView}
                            className={classes.settingItem}
                        >
                            <ViewModuleIcon />{" "}
                            {intl.formatMessage({
                                id: "TOOLBOX.SETTING.LAYOUT"
                            })}
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey="7"
                            onClick={() => jitsiMeeting.setIsSubTitle(!isSubTitle)}
                            className={classes.settingItem}
                        >
                            <SubtitlesIcon /> Subtitles
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Button variant="contained" className={classes.button} onClick={handleReset}>
                    Reset
                </Button>
            </div>
            <div id="toolbox" className={`${classes.toolbox} d-none d-lg-flex`}>
                <div id="toolboxLeft" className={classes.toolboxLeft}>
                    <ToggleButton
                        selected={isWithFloor}
                        onChanged={value => {
                            jitsiMeeting.setIsWithFloor(value);
                        }}
                    >
                        With Floor
                    </ToggleButton>
                    <ToggleButton
                        selected={isSubTitle}
                        onChanged={value => {
                            jitsiMeeting.setIsSubTitle(value);
                        }}
                    >
                        Subtitles
                    </ToggleButton>
                    <ToggleButton
                        selected={inputRoomname === ORIGINAL_ROOMNAME}
                        onChanged={() => {
                            jitsiMeeting.changeLangTrack(ORIGINAL_ROOMNAME);
                        }}
                    >
                        0 Floor
                    </ToggleButton>
                </div>
                <div id="toolboxCenter" className={classes.toolboxCenter}>
                    <ToggleButton
                        selected={isMicOn}
                        onChanged={value => {
                            jitsiMeeting.changeMicOn(value);
                        }}
                    >
                        <span style={{ width: 120 }}>Mic</span>
                    </ToggleButton>
                    <ToggleButton
                        selected={isMuted}
                        onChanged={value => {
                            if (isMicOn) jitsiMeeting.changeMute(value);
                        }}
                    >
                        {isMuted ? "Muted" : "Mute"}
                    </ToggleButton>
                    <Fab
                        color="secondary"
                        size="small"
                        aria-label="Chat"
                        className={`${classes.shareScreen} ${isNewMsg ? classes.chat : ""}`}
                        onClick={handleChat}
                    >
                        <MessageIcon />
                    </Fab>
                    {isVideoOn ? (
                        <Fab
                            color="secondary"
                            size="small"
                            aria-label="Video On"
                            onClick={handleVideoOn}
                            className={`${classes.videoMute} ${classes.shareScreen}`}
                            disabled={disableVideo}
                        >
                            <VideocamIcon />
                        </Fab>
                    ) : (
                        <Fab
                            color="inherit"
                            size="small"
                            aria-label="Video Off"
                            onClick={handleVideoOn}
                            className={`${classes.call} ${classes.videoMute} ${classes.shareScreen}`}
                            disabled={disableVideo}
                        >
                            <VideocamOffIcon />
                        </Fab>
                    )}
                </div>
                <div id="toolbox-right" className={classes.toolboxRight}>
                    <div className={classes.toolboxRight_left}>
                        <Fab
                            color="inherit"
                            size="small"
                            aria-label="HandOver"
                            onClick={handleHandOverReady}
                            className={classes.handleOver}
                            variant="extended"
                        >
                            <span style={{ width: 140 }}>
                                {isHandOverReady ? "Handover now" : "Ready for handover"}
                            </span>
                        </Fab>
                    </div>
                    <div className={classes.toolboxRight_right}>
                        <Fab
                            color="inherit"
                            size="small"
                            aria-label="CallEnd"
                            className={classes.call}
                            onClick={handleLeave}
                        >
                            <CallEndIcon />
                        </Fab>

                        <IconButton
                            color="primary"
                            onClick={() => handleEventInfo(!showInfoPanel)}
                            className={`${classes.volume} ${classes.mosaicButton} ${classes.toolbarItem}`}
                        >
                            <GroupIcon />
                        </IconButton>

                        <IconButton
                            color="primary"
                            aria-label="Layout"
                            className={`${classes.volume} ${classes.mosaicButton} ${classes.toolbarItem}`}
                            onClick={handleMosaicView}
                        >
                            <ViewModuleIcon />
                        </IconButton>
                        <Dropdown alignRight>
                            <Dropdown.Toggle
                                as={CustomToggle}
                                id="dropdown-basic"
                                drop={"up"}
                                navbar={false}
                            >
                                <IconButton
                                    color="primary"
                                    aria-label="More"
                                    className={classes.volume}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            </Dropdown.Toggle>
                            <Dropdown.Menu as={CustomMenu}>
                                <Dropdown.Item eventKey="1" onClick={handleVideoSetting}>
                                    <VideocamIcon />{" "}
                                    {intl.formatMessage({
                                        id: "VIDEO.VIDEO_INPUT"
                                    })}
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="3" onClick={handleAudioInputSetting}>
                                    <SettingsVoiceIcon />{" "}
                                    {intl.formatMessage({
                                        id: "VIDEO.AUDIO_INPUT"
                                    })}
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="2" onClick={handleAudioOutputSetting}>
                                    <SpeakerIcon />{" "}
                                    {intl.formatMessage({
                                        id: "VIDEO.AUDIO_OUTPUT"
                                    })}
                                </Dropdown.Item>
                                <Dropdown.Item
                                    eventKey="5"
                                    onClick={handleMosaicView}
                                    className={classes.settingItem}
                                >
                                    <ViewModuleIcon />{" "}
                                    {intl.formatMessage({
                                        id: "TOOLBOX.SETTING.LAYOUT"
                                    })}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button
                            variant="contained"
                            className={classes.button}
                            onClick={handleReset}
                        >
                            Reset
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        isMuted: state.event.isMuted,
        isMicOn: state.event.isMicOn,
        isVideoOn: state.event.isVideoOn,
        isHandOverReady: state.event.isHandOverReady,
        cameraDevices: state.event.cameraDevices,
        isChatBoxOpen: state.event.isChatBoxOpen,
        isNewMsg: state.event.isNewMsg,
        isRaise: state.event.isRaise,
        isMosaic: state.event.isMosaic,
        isCamera: state.event.isCamera,
        isShareOtherCamera: state.event.isShareOtherCamera,
        isToolbarShow: state.event.isToolbarShow,
        isWithFloor: state.event.isWithFloor,
        isSubTitle: state.event.isSubTitle,
        inputRoomname: state.event.inputRoomname,
        groupTranslator: state.event.groupTranslator
    };
};

const mapDispatchToProps = dispatch => {
    const actions = {
        endMeeting: () => dispatch(eventStore.actions.endMeeting()),
        openChatBox: isChatBoxOpen => dispatch(eventStore.actions.openChatBox(isChatBoxOpen)),
        setOpenAudioInputSettingDlg: isOpen =>
            dispatch(eventStore.actions.setOpenAudioInputSettingDlg(isOpen)),
        setOpenAudioOutputSettingDlg: isOpen =>
            dispatch(eventStore.actions.setOpenAudioOutputSettingDlg(isOpen)),
        setOpenVideoSettingDlg: isOpen =>
            dispatch(eventStore.actions.setOpenVideoSettingDlg(isOpen)),
        setIsRaise: isRaise => dispatch(eventStore.actions.setIsRaise(isRaise)),
        updateIsMosaic: isMosaic => dispatch(eventStore.actions.updateIsMosaic(isMosaic)),
        setMuteAll: isMuteAll => dispatch(eventsStore.actions.setMuteAll(isMuteAll))
    };
    return actions;
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Toolbar));
