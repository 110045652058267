import { SUBTITLE_HEIGHT } from "../CommonStyles-new";

/**
 *
 * @param {*} isMosaic if True, now layout is mosaic.
 * @param {*} currentPartitions
 */
export function calcWidth(isMosaic, currentPartitionsCount) {
    const mosaicELems = document.querySelectorAll("#remotePlace > div");

    let width = "100%";
    let maxWidth = "100%";
    if (isMosaic) {
        let currentPLength = currentPartitionsCount + 1;

        const lengthRoot = Math.sqrt(currentPLength);
        if (Math.floor(lengthRoot) === lengthRoot) {
            currentPLength = lengthRoot;
        } else {
            currentPLength = Math.floor(lengthRoot) + 1;
        }
        // 56 * 16 / 9 = 100
        // (m2 + p8) * 2 = 20, 20 - m2 + m6 = 24
        width = `calc(${100 / currentPLength}% - 24px)`;
        maxWidth = `calc(100% - 24px)`;
    }

    for (let i = 0; i < mosaicELems.length; i++) {
        mosaicELems[i].style.width = width;
        if (isMosaic) {
            mosaicELems[i].style.maxWidth = maxWidth;
        } else {
            mosaicELems[i].style.maxWidth = "initial";
        }
    }
}

export function calcSidePlaceHeight(isSubTitle) {
    console.log("🚀 ~ calcSidePlaceHeight ~ isSubTitle:", isSubTitle);
    const remotePlaceElem = document.getElementById("remotePlace");
    const localPlaceElem = document.getElementById("localPlace");
    if (remotePlaceElem) {
        remotePlaceElem.style.maxHeight = `${localPlaceElem.offsetHeight -
            56 -
            (isSubTitle ? SUBTITLE_HEIGHT : 0)}px`;
        remotePlaceElem.style.maxWidth = `${(localPlaceElem.offsetHeight -
            56 -
            (isSubTitle ? SUBTITLE_HEIGHT : 0)) /
            0.56}px`;
    }
}
