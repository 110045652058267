import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, Drawer, IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

function Sidebar(props) {
    const { showInfoPanel, setShowInfoPanel, children } = props;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("sm"));

    const handleCloseClick = () => {
        setShowInfoPanel(false);
    };

    return (
        <>
            {matches ? (
                <React.Fragment key="right">
                    <Drawer
                        anchor="right"
                        className="d-flex d-lg-none"
                        // variant="persistent"
                        open={showInfoPanel}
                        onClose={() => setShowInfoPanel(false)}
                    >
                        <Grid container justifyContent="center" style={{ width: 340 }}>
                            <IconButton
                                aria-label="delete"
                                // className={classes.margin}
                                size="small"
                                style={{
                                    position: "absolute",
                                    left: 0,
                                    top: 0
                                }}
                                onClick={handleCloseClick}
                            >
                                <CloseIcon />
                            </IconButton>
                            {children}
                        </Grid>
                    </Drawer>
                </React.Fragment>
            ) : (
                <Grid
                    container
                    justifyContent="center"
                    className={`${showInfoPanel ? "d-none d-lg-flex" : "d-none"}`}
                >
                    {children}
                </Grid>
            )}
        </>
    );
}

export default withRouter(Sidebar);
